import React, {useState} from "react";
import {ValidateVouchercode} from "../application/ValidateVouchercodeMake";
import {IFormDataService, RentattestFormObject} from "../application/FormDataService";
import {useTranslation} from "react-i18next";

interface VoucherCodeFieldProps {
  validationSucceededCallback: (b: boolean) => void,
  validationService: ValidateVouchercode,
  formdataService: IFormDataService
}

export function VoucherCodeField(this: any, {
  validationSucceededCallback,
  validationService,
  formdataService
}: VoucherCodeFieldProps) {
  const [error, setError] = useState("")
  const [isValidating, setIsValidating] = useState(false)
  const {t} = useTranslation();

  async function validate(voucherCode: string) {
    setError("")
    setIsValidating(true)
    validationSucceededCallback(false);
    validationService.validate(voucherCode)
      .then(result => {
        formdataService.save(new RentattestFormObject(voucherCode))
        setIsValidating(false)
        if (result?.status === "accepted") {
          validationSucceededCallback(true)
        } else {
          if (result?.status) {
            setError(result.status)
          }
        }
      })
      .catch(reason => console.warn)
  }

  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="vouchercode"
    >
      {t('orderForm.voucherCode')}
    </label>
    <div className="relative">
      <input
        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
        type="text"
        id="vouchercode"
        name="vouchercode"
        onChange={(e) => {
          validate(e.target.value)
        }}
        placeholder={t('orderForm.voucherCode')}
      />
      {isValidating ?
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 absolute right-0 top-3" xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        : null
      }
    </div>
    <span className={"text-red-400 text-sm " + (error ? "" : "invisible")}>{t('orderForm.invalidVoucherCode')}</span>
  </div>;
}
