export class RentattestFormObject {
  constructor(
    public vouchercode: string = "",
    public nrn: string = "",
    public firstname: string = "",
    public lastname: string = "",
    public email: string = "",
    public confirmEmail: string = "",
    public phone: string = "",
    public monthlyAmount: number | undefined = undefined
  ) {
  }
}

export interface IFormDataService {
  save(formData: RentattestFormObject): void

  load(): RentattestFormObject
}

export class SessionstorageFormDataService implements IFormDataService {
  KEY = "rentattest";

  load(): RentattestFormObject {
    let obj: RentattestFormObject = new RentattestFormObject();
    const item = sessionStorage.getItem(this.KEY);
    if (item && typeof item !== "undefined") {
      obj = JSON.parse(item);
    }
    return obj;
  }

  save(formData: RentattestFormObject): void {
    sessionStorage.setItem(this.KEY, JSON.stringify(formData))
  }
}

const cookieStorage = {
  getItem: (key: string): string | undefined => {
    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce<Record<string, string>>(
        (accumulation, [k, v]) => ({
          ...accumulation,
          [k.trim()]: v,
        }),
        {}
      );

    return cookies[key];
  },
  setItem: (key: string, value: string): void => {
    document.cookie = `${key}=${value}`;
  },
};

export class CookiestorageFormDataService implements IFormDataService {
  KEY = "rentattestvouchercode";

  load(): RentattestFormObject {
    let obj: RentattestFormObject = new RentattestFormObject();
    const item = cookieStorage.getItem(this.KEY);
    if (item && typeof item !== "undefined") {
      obj = new RentattestFormObject(item);
    }
    return obj;
  }

  save(formData: RentattestFormObject): void {
    cookieStorage.setItem(this.KEY, formData.vouchercode)
  }
}
