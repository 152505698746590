export interface RequestAttest {
  requestAttest(requestValues: RequestAttestRequest): Promise<RequestAttestResponse>
}

export class RequestAttestRequest {
  constructor(
    private ITSME_token: string,
    private First_Name: string,
    private Last_Name: string,
    private Email: string,
    private Phone: string,
    private Property_price: string,
    private Voucher: string,
    private Authorization = "!9aZ#4s%8K^1x",
  ) {
  }

  toJson(): string {
    const jsonWithSpaces = Object.fromEntries(
      Object.entries(this)
        .map(([key, value]) => {
          return [key.replace("_", " "), value]
        }))
    return JSON.stringify(jsonWithSpaces)
  }
}

export enum RequestAttestResponseStatus {
  SUCCESS = "okay",
  INVALID_AUTHORIZATION = "invalid authorization"
}

// Responses from webhook:
// 200 - {"status": "okay"}
// 401 - {"status": "invalid authorization"}
interface RequestAttestResponse {
  status: RequestAttestResponseStatus | string
}

export class RequestAtestMake implements RequestAttest {
  async requestAttest(requestValues: RequestAttestRequest): Promise<RequestAttestResponse> {
    const rawResponse = await fetch("https://hook.eu2.make.com/zfbqnf7ulqzzou4ovtc013x3xddqq2ts", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: requestValues.toJson()
    });
    return rawResponse.text()
      .then(value => {
          try {
            return JSON.parse(value)
          } catch (e) {
            console.warn(e)
            return value;
          }
        }
      );
  }
}

